.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prev,
.next {
    cursor: pointer;
    position: absolute;

    width: auto;
    margin-top: 25px;
    color: #fff !important;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media (min-width: 320px) {

    .prev,
    .next {
        margin-top: 155px;
    }

    .next-right {
        margin-left: 250px
    }

    .MuiButton-containedPrimary {
        font-size: 12px !important;
    }

    .mobile {
        border-bottom: 1px solid #929292 !important;
    }


    .contentManual {
        padding-left: 25px;
    }

}

@media (min-width: 360px) {
    .next-right {
        margin-left: 290px
    }


}

@media (min-width: 375px) {
    .next-right {
        margin-left: 310px
    }

    .MuiButton-containedPrimary {
        font-size: 14px !important;
    }
}

@media (max-width: 767px) {
    .next-right {
        right: 0;
        margin-right: 24px;
    }
}

@media (min-width: 768px) {

    .prev,
    .next {
        margin-top: 45px;
    }

    .next-right {
        margin-left: 475px
    }

    .totLaporan {
        padding-top: 10px;
        font-size: 16px;
    }

    .itemName {
        min-width: 200px;
    }

    .contentManual {
        padding: 5px 30px;
    }

    main {
        margin-top: 10px;
    }
    /* .img-slide{
        max-height: 360px !important;
    } */
}
@media (min-width: 1024px) {
    .img-slide{
        max-height: 500px !important;
    }
}

@media (min-width: 992px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 578px
    }

    .totLaporan {
        font-size: 19px;
    }
}

@media (min-width: 1200px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 695px
    }
}

.activeList {
    background-color: #bfbcbc;
}



.grafikMax>canvas {
    max-height: 400px !important;
}

.marginGrafik {
    margin-top: 20px;
}

.tabelCenter>th {
    text-align: center;
}

.deleteDialog {
    margin-top: 15px;
    cursor: pointer;
}

.bgRowCancel>td {
    color: #fff !important;
}

.bodySessionDetail {
    display: block;
    overflow: auto;
    width: 100%;
}

.login {
    background: #fbfbfb;
    border-radius: 8px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
    padding: 13px;
    margin: 0px -26px;
}

.MuiListItemText-root>.MuiListItemText-primary {
    color: #000;
}

.iconLogout>.MuiListItemIcon-root>.MuiSvgIcon-root,
.iconLogout>.MuiListItemText-root>.MuiListItemText-primary {
    color: #ff0000 !important;
}

.table-responsive-manual {
    width: 90%;
}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.iconMenu {
    height: 25px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.headerPaket {
    font-size: 18px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    letter-spacing: 0.0075em !important;
    text-align: center;
    color: #3e0500
}


.textDetailMenu {
    font-size: 12px !important;
}

.form>label {
    margin-bottom: 0px !important;
}

.MuiTypography-body1 {
    font-size: 15px;
}

.MuiFormControl-root {
    padding: 10px 0 !important;
}

.pdTop10 {
    padding-top: 10px;
}

.formLabel>.MuiFormGroup-root {
    margin-bottom: 10px;
}

.textDeleteJamaah {
    color: #f00;
    cursor: pointer;
    margin-left: 1px;
    margin-right: 1px;
}

.MuiListItem-gutters {
    padding-left: 24px !important;
    padding-right: 16px !important;
}

.textContactUs {
    font-size: 15px !important;
}

.img-maxTabel {
    max-height: 134px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.ketHeader{
    font-size: 13px;
    font-weight: 500;
}
.itemGet{
    margin-left: -23px;;
}
.MuiCardContent-root > a > p {
    color: #000 !important;
}
.colorRed{
    color: #f00 !important;
}
.content-full{
    position: relative;
    width: 100%;
}
.mgTopMin5{
    margin-top: -5px!important;
}
.mgTop5{
    margin-top: 5px!important;
}
.pdImage{
    padding: 5px;
}
.mgLRMin30{
    margin-left: -30px!important;
    margin-right: -30px!important;
}
.bgB60{
    margin-bottom: 60px;
}
.img-outlet{
    height: 200px;
}
.imgOutlet{
    margin: 10px 0;
}
.flex{
    display: flex;
}
.imgMenu{
    max-height: 200px;
    margin: 5px 0px;
}
.formReg{
    margin-top: 20px;
    margin-bottom: 20px;
}
.MuiFormControl-marginNormal{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
body {
  padding-top: 2.8rem;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e1e2e1 !important;
  color: #313131 !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.cursorHand {
  cursor: pointer; }

.MuiInputBase-input {
  color: #313131 !important; }

.MuiSelect-icon {
  color: #313131 !important; }

.MuiSvgIcon-colorPrimary {
  color: #bd2640 !important; }

.checkBoxTabel > span .MuiSvgIcon-root {
  color: #bd2640 !important; }

.basic-navbar-nav {
  color: #313131 !important; }

input {
  color: #313131 !important; }

.MuiCheckbox-root {
  color: #313131 !important; }

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ff7500 !important; }

textarea {
  color: #313131 !important; }

.MuiInput-underline:before {
  border-bottom: 1px solid #313131 !important; }

.MuiInput-underline:after {
  border-bottom: 1px solid #ff7500 !important; }

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
  background-color: #bd2640 !important;
  color: #ffffff !important; }

fieldset > legend {
  color: #313131 !important; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #313131;
  -webkit-box-shadow: 0 0 0px 1000px #e1e2e1 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

.checkBox {
  color: #313131 !important; }

.MuiOutlinedInput-notchedOutline {
  border-color: #313131 !important; }

label {
  color: #313131 !important; }

.paper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.avatar {
  margin: 8px;
  background-color: #bd2640 !important; }

.form {
  width: 100%;
  margin-top: 8px; }

.submit {
  margin: 24px 0 16px !important;
  background-color: #bd2640 !important;
  color: #fff !important; }

a {
  color: #e6e6e6 !important; }

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 10px 0 10px;
  z-index: 9999; }

.bg-navbar {
  background-color: #040716; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #30323a !important;
  border-color: #dee2e6 #dee2e6 #fff !important; }

.nav-tabs {
  border-bottom: 1px solid #a59e9e !important; }

.modal-content {
  background-color: #384161 !important; }

.MuiSwitch-colorSecondary.Mui-checked {
  color: #bd2640 !important; }

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #bd2640 !important; }

.btnTable {
  padding: 11px 15px; }

.navbar-light .navbar-toggler-icon {
  background-image: url("/navbar.png"); }

.MuiButton-outlinedPrimary {
  color: #bd2640 !important;
  border: 1px solid #ec7510 !important; }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #bd2640 !important;
  border-color: #dee2e6 #dee2e6 #a59e9e !important;
  color: #fff !important; }

.centered {
  display: flex;
  justify-content: center;
  align-items: center; }

.mg-top64 {
  margin-top: 64px; }

.hrefOutlet {
  color: #f2f2f2 !important;
  font-size: 1em;
  margin: 0 auto 0;
  text-decoration: underline; }

.MuiFormLabel-root {
  color: #313131 !important; }

.garis-bawah {
  border-bottom: 1px solid #313131;
  padding: 5px 0px; }

.navbar {
  padding: 0px 15px !important;
  margin: -4px 0px !important; }

.MuiTablePagination-actions {
  margin-left: -50px !important; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent; }

.MuiDialog-paper {
  background-color: #e1e2e1 !important;
  margin: 18px !important; }

.MuiDialog-paperWidthSm {
  width: 90% !important; }

.MuiButton-textPrimary {
  color: #bd2640 !important; }

.colorWhite {
  color: #000 !important; }

.colorBlack {
  color: #000 !important; }

@media (min-width: 768px) {
  .MuiTablePagination-caption {
    padding-right: 35px !important; } }

.skeleton-line > span > span.css-19bon8n {
  height: 1px; }

.colorRed {
  color: #bd2640 !important; }

.sizeIconHapusOutlet {
  font-size: 2.2em !important; }

.marginLeft20 {
  margin-left: 20px !important; }

.tabelWhite > th,
.tabelWhite > td {
  color: #313131 !important; }

.btn-primary {
  background-color: #bd2640 !important;
  border-color: #bd2640 !important; }

.btnAddManual {
  background-color: #006db3 !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important; }

.btnImportExcel {
  background-color: #99aa00 !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important; }

.btnImportExcelCatatan {
  background-color: #005b4f !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important; }

.btnAddMultiple {
  background-color: #00867d !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important; }

.MuiTypography-body2 {
  color: #313131 !important; }

.MuiPickersSlideTransition-transitionContainer > * {
  color: #030303 !important; }

.MuiPickersCalendarHeader-dayLabel {
  color: #030303 !important; }

.MuiPickersToolbar-toolbar {
  background-color: #bd2640 !important; }

.MuiTypography-subtitle1 {
  color: #fff !important; }

.MuiPickersCalendarHeader-iconButton {
  background-color: transparent !important; }

.MuiPickersDay-daySelected {
  background-color: #f5aaae !important;
  color: #fff !important; }

.MuiPickersModal-dialogRoot {
  min-width: 310px !important; }

.tangan {
  cursor: pointer; }

.paadingRightLeft15 {
  padding: 0 15px; }

.MuiAppBar-colorPrimary {
  position: fixed !important;
  color: #fff;
  background-color: #bd2640 !important; }

.DialogFont {
  color: #000 !important; }

.tabelForm > div .MuiFormControl-root > input .MuiInputBase-input {
  color: #000 !important; }

.marginTop15 {
  margin-top: 15px !important; }

.bodyDialog {
  margin-top: 65px; }

.MuiDialog-paperFullScreen {
  min-width: 320px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important; }

.MuiTab-textColorPrimary {
  color: #fff !important; }

.MuiTabs-centered {
  /* border-bottom: 2px solid #ded6d6; */
  background-color: #bd2640; }

.MuiTabs-indicator {
  background-color: #fff !important; }

button:focus {
  outline: 0px auto !important; }

.MuiTabs-root {
  margin-top: -9px !important; }

.bgTabel {
  background-color: #bd2640; }

.MuiDialogActions-root {
  border-top: 1px solid white; }

.MuiDialogTitle-root {
  border-bottom: 1px solid white; }

.bgContent-orderan {
  background-color: #b7b7b7;
  padding: 10px; }

.ubahTanggal {
  font-size: 14px;
  color: #3856f3;
  cursor: pointer;
  font-weight: bold;
  font-style: oblique; }

.bgRowCancel {
  background-color: #fb4646; }

.bgRowCancel:hover {
  background-color: #ec2828 !important; }

.MuiDialog-paperWidthSm {
  min-width: 310px !important; }

.font12 {
  font-size: 12px !important; }

.navbar {
  padding: 10px !important; }

.bg-dark {
  background-color: #bd2640 !important; }

.btnBack {
  color: #313131 !important;
  cursor: pointer; }

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
  background-color: #bd2640 !important;
  color: #ffffff !important; }

.border-bottom {
  border-bottom: 1px solid #929292 !important; }

.pilihOutlet {
  margin-top: -25px;
  color: #bd2640; }

.bgPilihOutlet {
  background-color: #bd2640; }

.pilihOutlet {
  margin-top: -25px;
  color: #bd2640; }

.colorLogin {
  color: #bd2640 !important; }

.manajemenOutlet {
  margin-top: 15px;
  color: #bd2640; }

.hrefColor {
  color: #bd2640 !important; }

.MuiTableCell-root {
  border-bottom: 1px solid #5d5252 !important; }

.btnRiwayatTrans {
  background-color: #bd2640 !important;
  margin-right: 10;
  color: #fff !important; }

/* Laporan */
.bg-totPenerimaan {
  background-color: #00701a !important;
  color: #fff; }

.bg-todTrans {
  background-color: #524c00 !important;
  color: #fff; }

.bg-todCabang {
  background-color: #005662 !important;
  color: #fff; }

.bg-pembayaran {
  background-color: #003d33 !important;
  color: #fff; }

.bg-laporan {
  background-color: #f3f3f3 !important;
  color: #333; }

.judul-kotak {
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 0px 0 0;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px; }

.box {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0; }

.right {
  float: right !important; }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 55%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }

.box-content {
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0; }

.isiBayar {
  font-size: 20px;
  font-weight: bold; }

.colorIconBranch {
  color: #333 !important; }

.box-report {
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  height: 110px; }

.bg-report1 {
  background-color: #324851 !important;
  color: #fff; }

.bg-report2 {
  background-color: #86AC41 !important;
  color: #fff; }

.bg-report3 {
  background-color: #34675c !important;
  color: #fff; }

.prev,
.next {
  background-color: #bd2640; }

.active-btnGroup {
  background-color: #ef3627 !important; }

.active-btnGroup:hover {
  background-color: #ef3627 !important; }

.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
  border-right: 1px solid #f99b98 !important; }

.MuiIconButton-label > .MuiSvgIcon-root {
  color: #383737 !important; }

/* baru */
th {
  text-align: none !important; }

.borderMenu {
  border: 3px solid #bd2640;
  padding: 40px 15px 0px;
  margin: 10px 1px; }

.menuName {
  margin-top: -60px;
  text-align: center; }

.menuName > span {
  font-size: 24px;
  color: #bd2640;
  font-weight: bold;
  background-color: #e1e2e1;
  padding: 0 7px; }

.btnColor,
.hrefColor {
  color: #bd2640 !important; }

.linkBlack {
  color: #000 !important; }

.bgHeaderTabel {
  background-color: #bd2640 !important;
  color: #fff !important; }

.MuiPickerDTTabs-tabs {
  background-color: #bd2640 !important; }

.MuiPickersClock-clock {
  background-color: #838383 !important; }

.MuiPickersClockPointer-pointer {
  background-color: #bd2640 !important; }

.MuiPickersClock-pin {
  background-color: #bd2640 !important; }

.MuiPickersClockNumber-clockNumber {
  color: rgba(249, 245, 245, 0.87) !important; }

.MuiPickersClockPointer-thumb {
  border: 14px solid #bd2640 !important; }

.table-hover tbody tr:hover {
  background-color: #cccccc !important;
  color: #131212 !important; }

td > .MuiSvgIcon-root {
  color: #bd2640 !important; }

.colorVoid {
  color: #000; }

.bgModal {
  background-color: #e1e2e1 !important; }

.boderSession {
  border-top: 3px solid #231e1e !important; }

.col-md-1,
.col-2 > .MuiSvgIcon-root {
  color: #ff1300 !important; }

button > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #252525 !important; }

.MuiGrid-root > button > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #fff !important; }

/* new */
.buttonExportExcel {
  color: #fff;
  background-color: #bd2640;
  padding: 5px;
  font-size: 16px;
  font-weight: bold; }

.bgHeaderTabel > th .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff; }

.bgHeaderTabel > th .MuiTableSortLabel-root:hover {
  color: #fff; }

.menuLaporan > .navbar {
  background-color: #bd2640; }

.namaLaporan {
  color: black;
  margin-top: 10px;
  text-align: center; }

@media (max-width: 767px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #3e3737 !important;
    background-color: #f1f0f0; }
  .nav-item > .nav-link {
    color: #f1f0f0 !important; } }

@media (min-width: 768px) {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffffff !important;
    background-color: #bd2640; }
  .nav-item > .nav-link {
    color: #000 !important; } }

.MuiListItemIcon-root > .MuiSvgIcon-root {
  color: #3a3636 !important; }

.MuiToolbar-root > button > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #fff !important; }

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #e1e2e1 !important;
  padding-top: 10px !important; }

.MuiInputBase-root > .MuiSvgIcon-root {
  color: #2c2a2a !important; }

.MuiIconButton-label > div > .MuiSvgIcon-root {
  color: #2c2a2a !important; }

.MuiTablePagination-actions {
  margin-left: -10px !important; }

.MuiTablePagination-actions > .MuiIconButton-root {
  padding: 5px !important; }

.bgButton {
  background-color: #2057c7 !important;
  border: 2px solid #99aed8 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(251, 0, 0, 0.19); }

nav > a {
  color: #000 !important; }

.MuiButton-label > .MuiSvgIcon-root {
  color: #fff !important; }

.MuiFormControl-root {
  padding-top: 5px !important; }

.ubahJumlah {
  font-size: 13px;
  color: #074ff1;
  font-family: sans-serif; }

.ketTransaksi {
  margin-bottom: 5px !important; }

.btnRiwayatTrans > .MuiSvgIcon-root {
  color: #fff !important; }

.formInputJamaah {
  border: 3px solid #bd2640;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px; }

.formPaketDetail {
  font-size: 14px !important; }

.MuiButton-contained.Mui-disabled {
  background-color: #bd2640 !important; }

.gantiPassword {
  color: #bd2640;
  font-size: 15px; }

.textColorprimary {
  color: #bd2640; }

.textDeleteJamaah > .MuiSvgIcon-root {
  color: #f00 !important; }

.btnPesanPaket {
  position: fixed !important;
  bottom: 0 !important;
  width: 90% !important;
  margin-top: 60px !important; }

.MuiBottomNavigationAction-root.Mui-selected {
  color: #bd2640 !important; }

.MuiFab-primary {
  color: #fff;
  background-color: #bd2640 !important; }

.btn3 {
  background-color: #61651d !important;
  color: #fff !important; }

.img-btn {
  max-height: 20px; }

.btn-card {
  background-color: #bd2640;
  border-radius: 11px;
  font-size: 16px;
  color: #fff;
  cursor: pointer; }

.border-btm {
  border-bottom: 1px solid #9c9e9c;
  margin: 0px 20px; }

.cardOrderPay {
  padding: 15px 10px;
  display: flex;
  width: 100%; }

.headCart {
  color: #bd2640;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline; }

.width100 {
  width: 100%; }

.MuiButton-label > .MuiSvgIcon-root {
  color: #403c3c !important; }

.pd10TB {
  padding: 10px 0px; }

.MuiButton-contained.Mui-disabled {
  background-color: #d67c8c !important; }

